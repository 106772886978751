import { Suggest2, Suggest2Props } from "@blueprintjs/select";
import { MenuItem2 } from "@blueprintjs/popover2";
import { Button, FormGroup, FormGroupProps } from "@blueprintjs/core";
import classNames from "classnames";

export interface SuggestProps<
  T extends Record<string, any> = Record<string, any>
> extends Omit<FormGroupProps, "children">,
    Omit<
      Suggest2Props<T>,
      | "items"
      | "inputValueRenderer"
      | "itemRenderer"
      | "itemPredicate"
      | "itemsEqual"
      | "noResults"
      | "onItemSelect"
      | "selectedItem"
      // | "onItemSelect"
    > {
  options: T[];
  labelKey?: keyof T;
  valueKey?: keyof T;
  itemKey?: keyof T;
  suffixKey?: keyof T;
  noResultsText?: string;
  value?: string | number;
  onChange?: (value?: string | number) => void;
  small?: boolean;
  large?: boolean;
  placeholder?: string;
}

export const Suggest = <T extends Record<string, any> = Record<string, any>>({
  className,
  contentClassName,
  helperText,
  inline,
  intent,
  label,
  labelFor,
  labelInfo,
  style,
  subLabel,
  options,
  value,
  onChange,
  labelKey = "label",
  valueKey = "value",
  itemKey,
  suffixKey,
  noResultsText = "无可选数据",
  popoverProps,
  inputProps,
  small = true,
  large,
  placeholder = "请选择",
  ...props
}: SuggestProps<T>) => {
  const selectedItem = options.find((item) => item[valueKey] === value);

  const itemRenderer: Suggest2Props<T>["itemRenderer"] = (item, props) => {
    if (!props.modifiers.matchesPredicate) return null;
    const key = item[itemKey ?? valueKey];
    return (
      <MenuItem2
        active={props.modifiers.active}
        disabled={props.modifiers.disabled}
        key={key}
        label={suffixKey ? item[suffixKey] : undefined}
        onClick={props.handleClick}
        onFocus={props.handleFocus}
        roleStructure="listoption"
        text={highlightText(item[labelKey], props.query)}
      />
    );
  };

  function areFilmsEqual(itemA: T, itemB: T) {
    // Compare only the titles (ignoring case) just for simplicity.
    return itemA[labelKey].toLowerCase() === itemB[labelKey].toLowerCase();
  }

  const filterFilm: Suggest2Props<T>["itemPredicate"] = (
    query,
    option,
    _index,
    exactMatch
  ) => {
    const normalizedTitle = option[labelKey].toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      const title = [normalizedTitle];
      if (suffixKey) title.push(option[suffixKey]);
      return title.join(" ").indexOf(normalizedQuery) >= 0;
    }
  };

  const handleItemSelect: Suggest2Props<T>["onItemSelect"] = (e) => {
    onChange?.(e[valueKey]);
  };

  const handleRemove = () => {
    onChange?.(undefined);
  };

  return (
    <FormGroup
      className={classNames("!mb-0", className)}
      {...{
        contentClassName,
        helperText,
        inline,
        intent,
        label,
        labelFor,
        labelInfo,
        style,
        subLabel,
      }}
    >
      <Suggest2<T>
        resetOnClose
        {...props}
        inputValueRenderer={(e) => e[labelKey]}
        items={options}
        itemsEqual={areFilmsEqual}
        itemPredicate={filterFilm}
        itemRenderer={itemRenderer}
        noResults={
          <MenuItem2
            disabled={true}
            text={noResultsText}
            roleStructure="listoption"
          />
        }
        selectedItem={selectedItem ?? null}
        onItemSelect={handleItemSelect}
        popoverProps={{
          minimal: true,
          ...popoverProps,
        }}
        inputProps={{
          small,
          large,
          placeholder,
          rightElement:
            selectedItem && !props.disabled ? (
              <Button minimal icon="cross" onClick={handleRemove} />
            ) : undefined,
          ...inputProps,
        }}
      />
    </FormGroup>
  );
};

function escapeRegExpChars(text: string) {
  // eslint-disable-next-line no-useless-escape
  return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}
function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens: React.ReactNode[] = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}
